
import { defineComponent, ref, computed } from 'vue';
import moment, { Moment } from 'moment';
import { message } from 'ant-design-vue';
import { settleTree, dispatchDownload } from '@/utils/illuminate';
import { request } from '@/utils/request';
import { createApiUrl, dealEmpty } from '@/utils/utils';
import { useStore } from 'vuex';

type FormDataType = {
  dateRange: Moment[];
  enterpriseId: string | number;
  departmentId: number | undefined;
  userNameOrPhone: string; // 员工姓名
  licensePlate: string,
  clockInStatus?: string | number; // 打卡情况 1-正常，2-异常情况
  departmentIdList?: Array<number>;
}

export default defineComponent({
  name: 'ExportModal',
  emits: ['complete'],
  setup(props, ctx) {
    const visible = ref(false);
    const store = useStore();
    const exportType = ref('auditExport');

    /* = -------------------------------------------------- = 提交日期 = -------------------------------------------------- = */
    let firstSelectTime: Moment | null;
    // 动态禁止超过九十天的日期 📅
    const isDateDisable = (currentDate: Moment) => {
      if (moment().valueOf() - currentDate.valueOf() < 0) return true;
      if (!firstSelectTime) return false;
      return Math.abs(firstSelectTime.valueOf() - currentDate.valueOf()) > 7776000000;
    };
    const onOpenChange = (value: boolean) => {
      if (!value) firstSelectTime = null;
    };

    const onCalendarChange = (dateRange: [Moment, Moment]) => {
      // eslint-disable-next-line prefer-destructuring
      firstSelectTime = dateRange[0];
    };

    /* = -------------------------------------------------- = 加载搜索用的部门树 = -------------------------------------------------- = */
    const departmentList = ref<any[]>([]);
    const loadEnterpriseDepartment = async (val:any) => {
      // 公司查询全部 部门为空
      if (val === '' || val === undefined) {
        departmentList.value = [];
        return;
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'), { params: { enterpriseId: val } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      departmentList.value = settleTree(res.data.departmentList,
        { key: 'id', title: 'departmentName', value: 'id' },
        'departmentList');
    };

    const content = computed<Array<Record<string, any>>>(() => [
      {
        label: '时间范围',
        name: 'dateRangeRadio',
        type: 'radio',
        sel_data: [{ key: 1, name: '提交时间' }, { key: 2, name: '审批时间' }],
        initialValue: 1,
      },
      { 
        label: '时间选择',
        name: 'dateRange',
        type: 'rangepicker',
        format: 'YYYY-MM-DD',
        placeholder: ['起始时间', '结束时间'],
        width: '230px',
        help: '最长可导出90个自然日数据',
        initialValue: [moment().add(-90, 'day'), moment()],
        disabledDate: isDateDisable,
        calendarChange: onCalendarChange,
        openChange: onOpenChange,
      },
      { 
        label: '公司',
        name: 'enterpriseId',
        type: 'currentEnterprise',
        withDefaultAll: true,
        initialValue: store.state.User.enterprise.enterpriseId,
        change: loadEnterpriseDepartment,
      },
      { 
        label: '部门',
        name: 'departmentId',
        type: 'tree_select',
        allowClear: true,
        showSearch: true,
        showLine: true,
        placeholder: '部门名称',
        searchPlaceholder: '请选择', 
        treeNodeFilterProp: 'title',
        treeData: departmentList.value,
        initialValue: undefined,
      },
      { 
        label: '员工',
        name: 'userNameOrPhone',
        type: 'input',
        allowClear: true,
        placeholder: '员工姓名/手机号码',
        initialValue: '',
      },
      { 
        label: '车牌号',
        name: 'licensePlate',
        type: 'input',
        placeholder: '请输入车牌号',
        allowClear: true, 
        initialValue: '',
      },
      { 
        label: '打点情况',
        name: 'clockInStatus',
        type: 'select',
        placeholder: '打点情况',
        allowClear: true, 
        fieldNames: { label: 'label', value: 'value' },
        initialValue: undefined,
        sel_data: [
          { label: '全部', value: '' },
          { label: '正常', value: 1 },
          { label: '异常情况', value: 2 },
        ],
      },
    ]);

    const handle = (type: string) => {
      visible.value = true;
      exportType.value = type;
      loadEnterpriseDepartment(store.state.User.enterprise.enterpriseId);
    };

    const exporting = ref(false);
    const sldModalRef = ref();
    const sldHandleConfirm = async (data: FormDataType) => {
      const params: Record<string, any> = { ...data };

      if (data.dateRange && data.dateRange.length) {
        if (params.dateRangeRadio === 1) {
          params.createStartTime = moment(data.dateRange[0].format('YYYY-MM-DD 00:00:00')).valueOf();
          params.createEndTime = moment(data.dateRange[1].format('YYYY-MM-DD 23:59:59')).valueOf();
        } else if (params.dateRangeRadio === 2) {
          params.startApproveTime = moment(data.dateRange[0].format('YYYY-MM-DD 00:00:00')).valueOf();
          params.endApproveTime = moment(data.dateRange[1].format('YYYY-MM-DD 23:59:59')).valueOf();
        }
        delete params.dateRange;
        delete params.dateRangeRadio;
      }

      params.searchLevel = !data.enterpriseId ? 1 : 0;
      params.enterpriseId = data.enterpriseId;
      params.approveStatus = exportType.value === 'auditExport' ? 0 : undefined;
      params.departmentIdList = data.departmentId ? [data.departmentId] : [];

      exporting.value = true;
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/excel/down/itinerary'), { responseType: 'blob', params: { ...dealEmpty(params) } });
      } catch (e: Error & any) {
        exporting.value = false;
        return message.error(e.message);
      }
      exporting.value = false;

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      dispatchDownload('行程单.zip', url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      ctx.emit('complete', false);
      sldModalRef.value?.reset();
      visible.value = false;
    };

    const sldHandleCancle = () => {
      ctx.emit('complete', false);
      visible.value = false;
    };

    return {
      visible,
      sldModalRef,
      content,

      exporting,
      handle,
      sldHandleConfirm,
      sldHandleCancle,
    };
  },
});
